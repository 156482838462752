<template>
  <div id="home">
    <vue-headful
            title="404 - Hair Design by Barry"
            description="Hello, I'm Barry Cook HAIR & BEAUTY STYLIST I've been in the hair designing business for over 30 years now."
    />
    <Navbar></Navbar>

    <div class="sectionError">

      <div class="container content fade-in-left" style="margin-bottom: 5%;">
        <h1 class="title heading">
          404<br>
          Page Not Found
        </h1>
        <div class="wrap">
        </div>
          <div class="row mt-4">
            <div class="col-sm">
              <p>
                Sorry about that, looks like the page you were trying to request wasn't found.
                Please double-check your spelling otherwise this link might not be valid anymore.
              </p>
            </div>
            <div class="col-sm">
              <p></p>
            </div>
          </div>

      </div>

    </div>


  <Footer></Footer>

  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style>
.content{
  padding-top:7%;
}
.sectionError{
  background: #304352;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #212121, #9c9fa2);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #212121, #9c9fa2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height:70vh;
}
.title{
  font-size: 50px;
}
.subtitle{
  font-size: 25px;
}
.highlight{
  background-color: #fff;
  color: #000 !important;
  padding: 5px 5px 5px 5px;
  max-width: 350px;
}

.btn-info {
  background: rgba(0, 0, 0, 1);
  color: white;
  border: #000 solid 1px;
  padding: 8px 15px 8px 15px;
  border-radius: 0.2rem;
  transition: 0.2s;
}
.btn-info:hover {
  opacity: 0.7;
  color: white;
  background-color: rgba(84, 89, 95, 1);
  border-color: #54595f;
  text-decoration: none;
  cursor: pointer;
}
.btn-info:focus {
  opacity: 0.7;
  color: #54595f;
  background-color: #ffffff !important;
  border-color: #000;
  box-shadow: 0 0 0 0.2rem #54595f;
  text-decoration: none;
  cursor: pointer;
}
.devider{
  margin-top: 2%;
  margin-bottom: 2%;
}
</style>
